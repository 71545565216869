import {useTranslation} from "@i18n/client";
import _ from "lodash";
import React, {memo, useMemo} from "react";
import {v5Pages} from "../../_common/_constants";
import Icon from "@components/Icon";
import Link from "next/link";
import SectionTitle from "@components/Nav/SectionTitle";
import RegionLink from "@components/Nav/RegionLink";
import {LocationForDrawer} from "./useDrawerRegions";

type Props = {
  locations: LocationForDrawer[];
};

type RegionLocations = {
  name: string;
  slug: string;
  count: number;
};

const Regions = ({locations}: Props) => {
  const {t} = useTranslation();

  // Regions with location count, grouped by state
  const regionGroups = useMemo(() => {
    const groupedByState = _.groupBy(locations, location => {
      return location.address.state === "CA" ? "CA" : "Others";
    });
    return _.mapValues(
      groupedByState,
      (locationsInState: LocationForDrawer[]): RegionLocations[] => {
        return _(locationsInState) // chain(locationsInState)
          .groupBy("region.slug")
          .map((groupedLocations, slug) => {
            return {
              // @ts-expect-error TS2532: Object is possibly 'undefined'.
              name: groupedLocations[0].region.name,
              slug: slug,
              count: groupedLocations.length,
            };
          })
          .sortBy(r => r.name)
          .value();
      },
    );
  }, [locations]);

  return (
    <div className="flex">
      <section className="p-10" aria-labelledby={t("Get Care nearby")}>
        <SectionTitle>{t("Get Care nearby")}</SectionTitle>

        <div className="mt-5">
          <Link
            href={v5Pages.locations}
            className="hover:bg-yellow-200 font-i flex p-2 rounded-[100px] !text-gray-1000"
            data-cy="locations-near-me"
          >
            {/*Avoiding using line-height (leading-***). It is overriden by icomoon.css*/}
            <Icon
              icon="locArrow"
              className="flex text-[1.75rem] w-[50px] h-[50px] bg-white items-center justify-center rounded-full"
            />
            <span className="flex-1 px-4">
              <span className="block whitespace-nowrap text-lg font-semibold">
                {t("Locations Near Me")}
              </span>
              <span className="block whitespace-nowrap text-sm">{t("Find care quickly")}</span>
            </span>
          </Link>
        </div>
      </section>
      <section
        className="bg-gray-100 bg-opacity-50 py-10 px-12 flex gap-8"
        aria-labelledby={t("Region list")}
      >
        <div className="flex flex-col gap-6">
          <SectionTitle>CALIFORNIA</SectionTitle>
          <ul>
            {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
            {regionGroups["CA"].map(({slug, name, count}) => (
              <RegionLink key={slug} slug={slug} name={name} count={count} />
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-6">
          <SectionTitle>{t("More Locations")}</SectionTitle>

          <ul className="columns-2">
            {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
            {regionGroups["Others"].map(({slug, name, count}) => (
              <RegionLink key={slug} slug={slug} name={name} count={count} />
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default memo(Regions);
