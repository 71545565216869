import {ItemGroup} from "@components/Footer/Footer";
import {useTranslation} from "@i18n/client";
import React from "react";

import DropdownList from "./DropdownList";

type Props = {
  sections: ItemGroup[];
};

const SmallMiddle: React.FC<Props> = ({sections}) => {
  const i18n = useTranslation();
  const combinedSections: ItemGroup[] = [
    // @ts-expect-error TS2322: Type 'ItemGroup | undefined' is not assignable to type 'ItemGroup'.
    sections[0],
    // @ts-expect-error TS2322: Type 'ItemGroup | undefined' is not assignable to type 'ItemGroup'.
    sections[1],
    // @ts-expect-error TS2322: Type 'ItemGroup | undefined' is not assignable to type 'ItemGroup'.
    sections[2],
    {
      id: "get-in-touch",
      header: i18n.t("Get in touch"),
      // @ts-expect-error TS2532, TS2532: Object is possibly 'undefined'.,  Object is possibly 'undefined'.
      items: [...sections.findById("follow").items, ...sections.findById("support").items],
    },
  ];

  return (
    <ul>
      {combinedSections.map((section, i) => (
        <DropdownList
          key={section.header}
          section={section}
          noBorder={i === combinedSections.length - 1}
        />
      ))}
    </ul>
  );
};

export default SmallMiddle;
