import React, {Suspense} from "react";

export const withSuspense = <T extends React.JSXElementConstructor<any>>(Component: T) =>
  function WithSuspense(props: React.ComponentProps<T>) {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  };
