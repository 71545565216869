import {v5Pages} from "../../_common/_constants";
import _ from "lodash";
import {useMemo} from "react";
import {RootStateLocation} from "../../../store/types";

export type LocationForDrawer = Pick<RootStateLocation, "region" | "address">;

const useDrawerRegions = (locations: LocationForDrawer[]) => {
  // Regions with location count, grouped by state
  const regionGroups = useMemo(() => {
    const groupedByState = _.groupBy(locations, location => {
      return location.address.state === "CA" ? "CA" : "Others";
    });
    return _.mapValues(groupedByState, (locationsInState: LocationForDrawer[]) => {
      return _(locationsInState) // chain(locationsInState)
        .groupBy("region.slug")
        .map((groupedLocations, slug) => {
          return {
            // @ts-expect-error TS2532: Object is possibly 'undefined'.
            title: groupedLocations[0].region.name,
            href: `${v5Pages.browse}/${slug}`,
            count: groupedLocations.length,
            subSubItems: null,
          };
        })
        .sortBy(r => r.title)
        .value();
    });
  }, [locations]);

  return useMemo(() => {
    const ca = {
      title: "California",
      href: null,
      count: null,
      subSubItems: regionGroups["CA"],
    };
    const others = regionGroups["Others"] || [];
    return _.sortBy([...others, ca], r => r.title);
  }, [regionGroups]);
};

export default useDrawerRegions;
